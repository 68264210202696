@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {

    .app {
      @apply bg-gradient-to-tl from-orange-400 to-amber-300 font-mono font-bold text-black
      dark:bg-gradient-to-r dark:from-teal-700 dark:to-cyan-800 dark:text-orange-200 transition-all duration-700 dark:transition-all dark:duration-1000 delay-200 dark:delay-150;
    }
    
    .navbar {
      @apply fixed w-full h-[50px] flex justify-between items-center px-4 z-10;
    }

    .theme-icon {
        @apply flex flex-row pr-3 items-center;
    }

    .theme-iconName {
        @apply justify-between pr-2 scale-0 transition-all duration-100 origin-right;
    }

    .mobileMenu {
      @apply absolute top-0 left-0 w-full h-screen bg-gradient-to-r 
      from-orange-100 via-rose-100 to-yellow-100 font-mono font-bold text-black flex flex-col justify-center items-center
      dark:bg-center dark:bg-gradient-to-r dark:from-transparent dark:via-black dark:to-transparent dark:text-orange-100;
    }
    
    .footer {
      @apply inset-x-0 bottom-0 w-full h-fit flex justify-between items-center text-center 
      py-3 px-3 bg-gradient-to-br dark:bg-gradient-to-bl dark:from-stone-900 dark:to-neutral-900 ;
    }
    
    .icon {
      @apply flex flex-wrap justify-between px-3 dark:fill-white dark:text-orange-100 dark:font-mono dark:hover:fill-teal-300;
    }

    .icon:hover {
      @apply scale-110;
    }
    .icon-name {
      @apply flex flex-wrap justify-between pl-2 py-1 scale-0 transition-all duration-100 origin-left text-black dark:text-orange-100 dark:font-mono;
    }


    .Linkedin {
      @apply hover:text-[#0A66C2];
    }

    .Github {
      @apply hover:text-[#6e5494];
    }
}

